import { Box, Button, Card, Heading } from '@hyphen/hyphen-components';
import { Outlet } from 'react-router-dom';

import { useOrganization } from '../../providers/OrganizationProvider';
import { TeamMembersList } from '../../components/teams/TeamMembersList';
import { Organization } from '../../services/organization';
import DynamicTeamCriteria from '../../components/teams/DynamicTeamCriteria';
import { TeamDetails } from '../../types/teams';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

export const TeamDetail = ({ team }: { team: TeamDetails }) => {
  const { organization = {} as Organization } = useOrganization();

  return (
    <>
      <Helmet title={`${team.name} Members`} />
      {team.channels?.length! > 0 && (
        <Box gap="2xl">
          <Heading as="h2" size="sm">
            Channels <span className="font-size-md font-color-secondary">({team.channels!.length})</span>
          </Heading>
          <div>
            {team.channels?.map((channel) => (
              <span key={channel.id}>
                {channel.type} - {channel.id},{' '}
              </span>
            ))}
          </div>
        </Box>
      )}

      <Card fontSize="sm">
        {team.type === 'dynamic' ? (
          <Card.Section>
            <DynamicTeamCriteria criteria={team.criteria || []} />
          </Card.Section>
        ) : (
          <Card.Header
            title="Team Members"
            description="Members of this team will inherit any access granted to the team"
            borderWidth="0 0 sm 0"
          >
            {team?.type === 'static' && (
              <Button asChild size="sm">
                <Link to="add">Add member</Link>
              </Button>
            )}
          </Card.Header>
        )}
        <Card.Section padding="0">
          <TeamMembersList organizationId={organization?.id || ''} teamId={team.id} teamType={team.type} />
        </Card.Section>
      </Card>

      <Outlet />
    </>
  );
};

export default TeamDetail;
