import { Route, Routes, useParams } from 'react-router-dom';
import { useGetBillingAccountByIdQuery } from '../services/billingAccount';
import { Spinner } from '@hyphen/hyphen-components';
import { ErrorPage } from './Error';
import { TabNav, TabNavLink } from '../components/TabNav';
import { BillingAccountDetails } from '../components/billing/BillingAccountDetails';
import { Helmet } from 'react-helmet';
import PageHeader from '../components/PageHeader';
import { UserSidebar } from '../components/sidebar/UserSidebar';
import { BillingAccountUsage } from '../components/billing/usage/BillingAccountUsage';
import { BillingAccountAccessDetails } from '../components/billing/access/BillingAccountAccessDetails';

export const Billing = () => {
  const { billingAccountId } = useParams();

  const { isLoading, error, data } = useGetBillingAccountByIdQuery(billingAccountId!);

  const renderBody = () => {
    if (isLoading && !data) {
      return <Spinner />;
    }

    if (error || !data) {
      return <ErrorPage />;
    }

    return (
      <>
        <TabNav>
          <TabNavLink to="">Overview</TabNavLink>
          <TabNavLink to="usage">Usage</TabNavLink>
          <TabNavLink to="access">Access</TabNavLink>
        </TabNav>
        <Routes>
          <Route path="" element={<BillingAccountDetails billingAccount={data} mode="edit" />} />
          <Route path="usage" element={<BillingAccountUsage />} />
          <Route
            path="access"
            element={<BillingAccountAccessDetails billingAccountId={data.id} owners={data.owners} mode="edit" />}
          />
        </Routes>
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>Billing</title>
      </Helmet>
      <UserSidebar>
        <PageHeader title="Billing" description="Manage your billing information and usage" />
        {renderBody()}
      </UserSidebar>
    </>
  );
};

export default Billing;
