import {
  Collapsible,
  SidebarMenuItem,
  CollapsibleTrigger,
  SidebarMenuButton,
  Box,
  Icon,
  CollapsibleContent,
  SidebarMenuSub,
  SidebarMenuSubItem,
  SidebarMenuSubButton,
  SidebarGroup,
} from '@hyphen/hyphen-components';
import { IconName } from '@hyphen/hyphen-components/dist/types';
import { NavLink, useLocation } from 'react-router-dom';
import ComingSoon from '../ComingSoon';
import MainNavItem from '../MainNavItem';

export interface ISidebarItem {
  label: string;
  icon: IconName;
  link?: string;
  disabled?: boolean;
  items?: { label: string; link: string; key: string }[];
}

interface SidebarItemProps extends ISidebarItem {
  onClick: () => void;
}

const SidebarItem = ({ label, icon, link, disabled, items = [], onClick }: SidebarItemProps) => {
  const location = useLocation();

  const isNestedActive = items.some((item) => location.pathname.includes(item.link));

  return items.length > 0 ? (
    <Collapsible key={label} asChild defaultOpen={isNestedActive}>
      <SidebarGroup style={{ padding: '0' }}>
        <CollapsibleTrigger asChild>
          <SidebarMenuButton className="p-left-lg">
            <Box width="24px" height="24px" justifyContent="center" alignItems="center">
              <Icon name={icon as IconName} color="disabled" size="lg" />
            </Box>
            {label}
            <Icon name="caret-sm-right" className="m-left-auto transform data-[state=open]:rotate-90" />
          </SidebarMenuButton>
        </CollapsibleTrigger>
        <CollapsibleContent className="p-left-sm">
          <SidebarMenuSub>
            {items.map((subItem) => (
              <SidebarMenuSubItem key={subItem.key}>
                <SidebarMenuSubButton asChild>
                  <NavLink to={subItem.link} end className="navlink fade-in" onClick={onClick}>
                    {subItem.label}
                  </NavLink>
                </SidebarMenuSubButton>
              </SidebarMenuSubItem>
            ))}
          </SidebarMenuSub>
        </CollapsibleContent>
      </SidebarGroup>
    </Collapsible>
  ) : (
    <SidebarMenuItem>
      {disabled ? (
        <MainNavItem iconName={icon as IconName} isDisabled>
          {label}
          <ComingSoon className="m-left-sm" />
        </MainNavItem>
      ) : (
        <NavLink to={link || ''} end className="navlink" onClick={onClick}>
          <MainNavItem iconName={icon as IconName}>{label}</MainNavItem>
        </NavLink>
      )}
    </SidebarMenuItem>
  );
};

export default SidebarItem;
