import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import { CreatePaymentMethodForm } from './CreatePaymentMethodForm';
import { Appearance } from '@stripe/stripe-js';
// @ts-ignore - Design tokens
import uiTokens from '@hyphen/hyphen-design-tokens/build/json/variables';
import { useTheme } from '@hyphen/hyphen-components';

export interface CreatePaymentMethodProps {
  variant?: 'registration' | 'billing';
  billingAccountId: string;
  onComplete: () => void;
}

export function CreatePaymentMethod({
  variant = 'registration',
  billingAccountId,
  onComplete,
}: CreatePaymentMethodProps) {
  const stripeKey = process.env.REACT_APP_STRIPE_KEY || '';
  const stripePromise = loadStripe(stripeKey);
  const { isDarkMode } = useTheme();
  const colorScheme = isDarkMode ? 'darkValue' : 'value';
  const dangerColor = variant === 'registration' ? '#ffcccb' : uiTokens.color.font.danger[colorScheme];
  const backgroundColor = variant === 'registration' ? '#ffffff0d' : uiTokens.color.background.primary[colorScheme];
  const borderColor =
    variant === 'registration' ? 'rgba(255, 255, 255, 0.1)' : uiTokens.color.border.default[colorScheme];
  const fontColor = variant === 'registration' ? '#ffffffb3' : uiTokens.color.font.base[colorScheme];
  const fontColorSecondary = variant === 'registration' ? '#ffffff80' : uiTokens.color.font.secondary[colorScheme];
  const fontColorPlaceholder =
    variant === 'registration' ? '#ffffff4d' : uiTokens.color.font.placeholder[colorScheme];

  const appearance: Appearance = {
    theme: 'stripe',
    labels: 'floating',
    variables: {
      borderRadius: uiTokens.size['border-radius'].sm.value,
      colorDanger: dangerColor,
      colorBackground: backgroundColor,
      colorText: fontColor,
      colorPrimary: fontColor,
      colorTextSecondary: fontColorSecondary,
      colorTextPlaceholder: fontColorPlaceholder,
    },
    rules: {
      '.Tab': {
        border: `1px solid ${borderColor}`,
        borderRadius: 'var(--borderRadius)',
      },
      '.Input': {
        border: `1px solid ${borderColor}`,
        borderRadius: 'var(--borderRadius)',
        padding: `${uiTokens.size.spacing.xs.value} ${uiTokens.size.spacing.lg.value}`,
      },
      '.Input:focus': {
        // border: `1px solid ${uiTokens.color.border.active[colorScheme]}`,
        // borderColor: uiTokens.color.border.hover[colorScheme],
      },
    },
  };

  return (
    <Elements
      stripe={stripePromise}
      options={{ mode: 'setup', currency: 'usd', payment_method_types: ['card', 'us_bank_account'], appearance }}
    >
      <CreatePaymentMethodForm variant={variant} billingAccountId={billingAccountId} onComplete={onComplete} />
    </Elements>
  );
}
