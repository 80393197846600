import { toast, useOpenClose } from '@hyphen/hyphen-components';
import { useCallback } from 'react';
import { useDeleteTeamMutation } from '../../services/teams';
import { useNavigate } from 'react-router-dom';
import { ConfirmModal } from '../common/ConfirmModal';
import { useOrganizationAbilityContext } from '../auth/OrganizationAbilityProvider';
import { EntityNames } from '@hyphen/nucleus/dist/types';
import { DeleteItemCard } from '../common/DeleteItemCard';

export default function DeleteTeam({ team, organizationId }: { team: any; organizationId: string }) {
  const { isOpen: isDeleteModalOpen, handleOpen: openDeleteModal, handleClose: closeDeleteModal } = useOpenClose();

  const ability = useOrganizationAbilityContext();
  const canDeleteTeam = ability.can('delete', EntityNames.Team);

  const navigate = useNavigate();

  const [deleteTeam, { isLoading: isDeleting, error }] = useDeleteTeamMutation();

  const handleDelete = useCallback(async () => {
    const { error } = await deleteTeam({ orgId: organizationId, id: team.id });
    if (!error) {
      toast.success('Team deleted');
      closeDeleteModal();
      navigate(`/${organizationId}/teams`);
    }
  }, [deleteTeam, organizationId, team.id, closeDeleteModal, navigate]);

  if (canDeleteTeam) {
    return (
      <>
        <DeleteItemCard
          itemLabel="Team"
          description=" Deleting the team will remove it from the organization and delete  its distribution list"
          onDelete={openDeleteModal}
        />
        <ConfirmModal
          message={`Are you sure you want to delete ${team.name}?`}
          confirmButtonLabel="Delete"
          isOpen={isDeleteModalOpen}
          onClose={closeDeleteModal}
          onConfirm={handleDelete}
          isLoading={isDeleting}
          title={`Delete ${team.name}`}
          error={error}
        />
      </>
    );
  }

  return null;
}
