import { Button, Card } from '@hyphen/hyphen-components';

export const DeleteItemCard = ({
  itemLabel = 'item',
  description = 'Once deleted, it will be gone forever. Please be certain.',
  onDelete,
}: {
  itemLabel: string;
  description?: string;
  onDelete: () => void;
}) => (
  <Card borderColor="danger" style={{ flexShrink: '0' }}>
    <Card.Header title={`Delete ${itemLabel}`} description={description} alignItems="center">
      <Button variant="danger" size="sm" onClick={onDelete}>
        Delete
      </Button>
    </Card.Header>
  </Card>
);
