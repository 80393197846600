import { Box, Spinner } from '@hyphen/hyphen-components';
import { Helmet } from 'react-helmet';
import PageHeader from '../../components/PageHeader';
import { BillingAccountDetails } from '../../components/billing/BillingAccountDetails';
import { OrganizationUsage } from '../../components/billing/usage/OrganizationUsage';
import { TabNav, TabNavLink } from '../../components/TabNav';
import { Route, Routes } from 'react-router-dom';
import { BreadCrumbPortal } from '../../components/common/Breadcrumb';
import { useOrganization } from '../../providers/OrganizationProvider';
import { Organization, useGetOrganizationBillingAccountQuery } from '../../services/organization';
import { ErrorPage } from '../Error';
import { useAuth } from '../../components/auth/authProvider';
import { useEffect, useState } from 'react';
import { BillingAccountAccessDetails } from '../../components/billing/access/BillingAccountAccessDetails';

export const Billing = () => {
  const { organization = {} as Organization } = useOrganization();

  const { isLoading, error, data } = useGetOrganizationBillingAccountQuery(organization.id);

  const { user } = useAuth();

  const [isOwner, setIsOwner] = useState(false);

  useEffect(() => {
    if (data) {
      const isOwner = data?.owners?.some((owner) => owner.userId === user?.userId);
      setIsOwner(isOwner);
    }
  }, [data, user?.userId]);

  const renderBody = () => {
    if (isLoading && !data) {
      return <Spinner />;
    }

    if (error || !data) {
      return <ErrorPage />;
    }

    return (
      <>
        <TabNav>
          <TabNavLink to="">Overview</TabNavLink>
          <TabNavLink to="usage">Usage</TabNavLink>
          <TabNavLink to="access">Access</TabNavLink>
        </TabNav>
        <Routes>
          <Route
            path=""
            element={<BillingAccountDetails billingAccount={data} mode={isOwner ? 'edit' : 'view'} />}
          />
          <Route path="usage" element={<OrganizationUsage />} />
          <Route
            path="access"
            element={
              <BillingAccountAccessDetails
                billingAccountId={data.id}
                owners={data.owners}
                mode={isOwner ? 'edit' : 'view'}
              />
            }
          />
        </Routes>
      </>
    );
  };

  return (
    <Box width="100" maxWidth="9xl" gap="4xl" alignItems="flex-start">
      <Helmet>
        <title>Billing</title>
      </Helmet>
      <PageHeader title="Billing" description="View your billing information and usage" />
      <BreadCrumbPortal />
      {renderBody()}
    </Box>
  );
};

export default Billing;
