import { Box, Button, Card, Table } from '@hyphen/hyphen-components';
import { useCreatePublicKeyMutation, useGetPublicKeysQuery } from '../../services/publicKeys';
import ClickToCopy from '../common/ClickToCopy';
import { useCallback } from 'react';
import { ApiError } from '../ApiError';

export default function PublicKeyList({
  projectId,
  projectName,
  organizationId,
}: {
  projectId: string;
  projectName: string;
  organizationId: string;
}) {
  const { data, isLoading, error } = useGetPublicKeysQuery({
    organizationId,
    projectId,
  });

  const [createPublicKey, { isLoading: isCreating }] = useCreatePublicKeyMutation();

  const handleCreate = useCallback(async () => {
    await createPublicKey({
      publicKey: {
        name: `${projectName} Public Key`,
        organizationId,
        projectId,
      },
    });
  }, [createPublicKey, organizationId, projectId, projectName]);

  const columnConfig = [
    {
      heading: 'Name',
      dataKey: 'name',
      width: 200,
    },
    {
      heading: 'Key',
      dataKey: 'key',
      render: (key: any) => {
        return <ClickToCopy text={key} displayText={`${key.substring(0, 16)}...`} fontSize="xs" />;
      },
    },
  ];

  if (!isLoading && error) {
    return <ApiError error={error} />;
  }

  return (
    <Card fontSize="sm">
      <Card.Header
        title="Public Key"
        description="Use a public key to authenticate your application with the Hyphen API to provide it limited, read-only
              access to features like Toggle evaluations."
        borderWidth="0 0 sm 0"
        alignItems="center"
      >
        {(data || []).length === 0 && (
          <Box minWidth="108px">
            <Button size="sm" variant="primary" iconPrefix="add" isLoading={isCreating} onClick={handleCreate}>
              Public Key
            </Button>
          </Box>
        )}
      </Card.Header>
      {!isLoading && (data || []).length > 0 ? (
        <Card.Section padding="lg 0 0 0">
          <Table
            rowKey="id"
            isLoading={isLoading}
            columns={columnConfig}
            rows={data || []}
            isScrollable={{ x: true, y: false }}
          />
        </Card.Section>
      ) : null}
    </Card>
  );
}
