import { Button } from '@hyphen/hyphen-components';
import { Connection, ConnectionType } from '../../../types/connections';
import { IntegrationType } from '../../../types/integrations';

type ConnectionUrl = { label: string; href: string } | undefined;

const buildAwsUrl = (connection: Connection): ConnectionUrl => {
  switch (connection.type) {
    case ConnectionType.PermissionGroup:
      return {
        label: connection.config.name,
        href: `https://console.aws.amazon.com/singlesignon/home#!/instances/${connection.config.instanceId}/groups/groupDetails/${connection.config.groupId}`,
      };
    case ConnectionType.User:
      return {
        label: connection.config.email,
        href: `https://console.aws.amazon.com/singlesignon/home#!/instances/${connection.config.instanceId}/users/userDetails/userId=${connection.config.userId}&directoryId=&realm=`,
      };
    case ConnectionType.CloudWorkspace:
      return {
        label: connection.config.name,
        href: `https://console.aws.amazon.com/organizations/v2/home/accounts/${connection.config.accountId}`,
      };
    case ConnectionType.Folder:
      return {
        label: connection.config.name,
        href: `https://console.aws.amazon.com/organizations/v2/home/organizational-units/${connection.config.organizationUnitId}`,
      };
    default:
      return undefined;
  }
};

const buildAzureUrl = (connection: Connection): ConnectionUrl => {
  switch (connection.type) {
    case ConnectionType.PermissionGroup:
      return {
        label: connection.config.groupName,
        href: `https://portal.azure.com/#view/Microsoft_AAD_IAM/GroupDetailsMenuBlade/~/Overview/groupId/${connection.config.groupId}/menuId/`,
      };
    case ConnectionType.CloudWorkspace:
      return connection.config.tenantId && connection.config.resourceGroupId
        ? {
          label: connection.config.resourceGroupName,
          href: `https://portal.azure.com/#@${connection.config.tenantId}/resource/${connection.config.resourceGroupId}/overview`,
        }
        : undefined;
    case ConnectionType.User:
      return {
        label: connection.config.email,
        href: `https://portal.azure.com/#view/Microsoft_AAD_UsersAndTenants/UserProfileMenuBlade/~/overview/userId/${connection.config.userId}/hidePreviewBanner~/true`,
      };
    default:
      return undefined;
  }
};

const buildGoogleWorkspaceUrl = (connection: Connection): ConnectionUrl => {
  switch (connection.type) {
    case ConnectionType.User:
      return {
        label: connection.config.email,
        href: `https://admin.google.com/u/0/ac/users/${connection.config.userId}`,
      };
    case ConnectionType.DistributionList:
      return {
        label: connection.config.email,
        href: `https://admin.google.com/u/0/ac/groups/${connection.config.distributionListId}`,
      };
    default:
      return undefined;
  }
};

const buildGoogleCloudUrl = (connection: Connection): ConnectionUrl => {
  switch (connection.type) {
    case ConnectionType.User:
      return {
        label: connection.config.email,
        href: `https://admin.google.com/u/0/ac/users/${connection.config.userId}`,
      };
    case ConnectionType.PermissionGroup:
      return {
        label: connection.config.groupName,
        href: `https://admin.google.com/u/0/ac/groups/${connection.config.groupId}`,
      };
    case ConnectionType.Folder:
      return {
        label: connection.config.folderName,
        href: `https://console.cloud.google.com/home/dashboard?folder=${connection.config.folderId?.replace(
          'folders/',
          '',
        )}`,
      };
    case ConnectionType.CloudWorkspace:
      return {
        label: connection.config.projectName,
        href: `https://console.cloud.google.com/home/dashboard?project=${connection.config.projectId?.replace(
          'projects/',
          '',
        )}`,
      };
    default:
      return undefined;
  }
};

const buildGithubUrl = (connection: Connection): ConnectionUrl => {
  switch (connection.type) {
    case ConnectionType.User:
      return {
        label: `@${connection.config.userId}`,
        href: `https://github.com/orgs/${connection.config.orgSlug}/people/${connection.config.userId}`,
      };
    case ConnectionType.PermissionGroup:
      return {
        label: connection.config.teamName,
        href: `https://github.com/orgs/${connection.config.orgSlug}/teams/${connection.config.teamSlug}`,
      };
    case ConnectionType.ProjectBoard:
      return {
        label: connection.config.title,
        href: `https://github.com/orgs/${connection.config.orgSlug}/projects/${connection.config.projectSlug}`,
      };
    case ConnectionType.CodeRepository:
      return {
        label: connection.config.repositoryName,
        href: `https://github.com/${connection.config.orgSlug}/${connection.config.repositoryName}`,
      };
    default:
      return undefined;
  }
};

const buildSlackUrl = (connection: Connection): ConnectionUrl => {
  switch (connection.type) {
    case ConnectionType.Channel:
      return {
        label: `#${connection.config.name}`,
        href: `slack://channel?team=${connection.config.workspaceId}&id=${connection.config.id}`,
      };
    case ConnectionType.User:
      return connection.config.userName && connection.config.workspaceId
        ? {
          label: `@${connection.config.userName}`,
          href: `slack://user?team=${connection.config.workspaceId}&id=${connection.config.userId}`,
        }
        : undefined;
    default:
      return undefined;
  }
};

const buildOffice365Url = (connection: Connection): ConnectionUrl => {
  switch (connection.type) {
    case ConnectionType.DistributionList:
      return {
        label: connection.config.groupName,
        href: `https://portal.azure.com/#view/Microsoft_AAD_IAM/GroupDetailsMenuBlade/~/Overview/groupId/${connection.config.groupId}/menuId/`,
      };
    case ConnectionType.User:
      return {
        label: connection.config.email,
        href: `https://portal.azure.com/#view/Microsoft_AAD_UsersAndTenants/UserProfileMenuBlade/~/overview/userId/${connection.config.userId}/hidePreviewBanner~/true`,
      };
    default:
      return undefined;
  }
};

const buildNewRelicUrl = (connection: Connection): ConnectionUrl => {
  switch (connection.type) {
    case ConnectionType.PermissionGroup:
      return {
        label: connection.config.groupName,
        href: `https://one.newrelic.com/admin-portal/organizations/organization-detail`,
      };
    default:
      return undefined;
  }
};

const buildNpmUrl = (connection: Connection): ConnectionUrl => {
  switch (connection.type) {
    case ConnectionType.PermissionGroup:
      return {
        label: connection.config.teamName,
        href: `https://www.npmjs.com/settings/${connection.config.teamName}/packages`,
      };
    case ConnectionType.CodeRegistry:
      return {
        label: connection.config.packageName,
        href: `https://www.npmjs.com/package/${connection.config.packageName}`,
      };
    default:
      return undefined;
  }
};

const buildJiraUrl = (connection: Connection): ConnectionUrl => {
  switch (connection.type) {
    case ConnectionType.PermissionGroup:
      return {
        label: connection.config.groupName,
        href: `https://admin.atlassian.com/o/${connection.config.organizationId}/groups/${connection.config.groupId}`,
      };
    case ConnectionType.ProjectBoard:
      return {
        label: connection.config.projectName,
        href: `https://${connection.config.cloudId}.atlassian.net/jira/software/projects/${connection.config.projectKey}/boards/1`,
      };
    case ConnectionType.User:
      return {
        label: connection.config.email,
        href: `https://admin.atlassian.com/o/${connection.config.organizationId}/users/${connection.config.userId}`,
      };
    default:
      return undefined;
  }
};

const buildIncidentIoUrl = (connection: Connection): ConnectionUrl => {
  switch (connection.type) {
    case ConnectionType.PermissionGroup:
      return {
        label: connection.config.teamName,
        href: `https://app.incident.io/~/catalog/Custom["Team"]/${connection.config.teamId}`,
      };
    case ConnectionType.User:
      return {
        label: connection.config.email,
        href: `https://app.incident.io/~/catalog/User/${connection.config.userId}`,
      };
    case ConnectionType.IncidentManagement:
      return {
        label: connection.config.serviceName,
        href: `https://app.incident.io/~/catalog/Custom["Service"]/${connection.config.serviceId}`,
      };
    default:
      return undefined;
  }
};

const buildMicrosoftTeamsUrl = (connection: Connection): ConnectionUrl => {
  switch (connection.type) {
    case ConnectionType.PermissionGroup:
    case ConnectionType.Channel:
      return {
        label: connection.config.name,
        href: connection.config.externalUrl,
      };
    case ConnectionType.User:
      return {
        label: connection.config.email,
        href: `https://portal.azure.com/#view/Microsoft_AAD_UsersAndTenants/UserProfileMenuBlade/~/overview/userId/${connection.config.userId}/hidePreviewBanner~/true`,
      };
    default:
      return undefined;
  }
}

export const buildConnectionUrl = (connection: Connection): ConnectionUrl => {
  switch (connection.organizationIntegration.type) {
    case IntegrationType.aws:
      return buildAwsUrl(connection);
    case IntegrationType.azure:
      return buildAzureUrl(connection);
    case IntegrationType.googleWorkspace:
      return buildGoogleWorkspaceUrl(connection);
    case IntegrationType.googleCloud:
      return buildGoogleCloudUrl(connection);
    case IntegrationType.github:
      return buildGithubUrl(connection);
    case IntegrationType.slack:
      return buildSlackUrl(connection);
    case IntegrationType.newRelic:
      return buildNewRelicUrl(connection);
    case IntegrationType.npm:
      return buildNpmUrl(connection);
    case IntegrationType.office365:
      return buildOffice365Url(connection);
    case IntegrationType.incidentIo:
      return buildIncidentIoUrl(connection);
    case IntegrationType.jira:
      return buildJiraUrl(connection);
    case IntegrationType.microsoftTeams:
      return buildMicrosoftTeamsUrl(connection);
    default:
      return undefined;
  }
};

export interface ConnectionItemProps {
  connection: Connection;
}

export const ConnectionLink = ({ connection }: ConnectionItemProps) => {
  if (connection.status !== 'Ready') {
    return null;
  }

  const connectionUrl = buildConnectionUrl(connection);

  if (!connectionUrl) {
    return null;
  }

  return (
    <Button size="sm" variant="secondary" iconSuffix="launch-app" asChild>
      <a href={connectionUrl.href} target="_blank" rel="noreferrer">
        {connectionUrl.label}
      </a>
    </Button>
  );
};
