import { PropsWithChildren } from 'react';
import {
  Card,
  Icon,
  Sidebar,
  SidebarContent,
  SidebarGroup,
  SidebarHeader,
  SidebarInset,
  SidebarMenu,
  SidebarMenuItem,
  SidebarProvider,
  SidebarTrigger,
  useIsMobile,
} from '@hyphen/hyphen-components';
import { Link, NavLink, useLocation } from 'react-router-dom';
import MainNavItem from '../MainNavItem';
import { ScrollableMainLayout } from '../ScrollableMainLayout';
import { useOrganizationsList } from '../../providers/OrganizationsListProvider';
import { useGetBillingAccountsQuery } from '../../services/billingAccount';
import SidebarItem from './SidebarItem';

export const UserSidebar = ({ children }: PropsWithChildren) => {
  const { organizations = [], isLoading: isOrgListLoading } = useOrganizationsList();

  const location = useLocation();
  const backOrgId = location.state?.backOrgId || localStorage.getItem('orgId');

  const { data: billingAccounts } = useGetBillingAccountsQuery();

  const isMobile = useIsMobile();

  return (
    <SidebarProvider>
      <Sidebar side="left" collapsible="offcanvas">
        <SidebarHeader>
          <SidebarGroup>
            <SidebarMenu>
              <SidebarMenuItem>
                <Link
                  to={`/${backOrgId || organizations[0]?.id}`}
                  className="display-flex align-items-center flex-direction-row g-md p-v-md"
                >
                  <Icon name="caret-left" aria-label="back to accounts" />
                  {(!isOrgListLoading && organizations.find((org) => org.id === backOrgId)?.name) ||
                    organizations[0]?.name}
                </Link>
              </SidebarMenuItem>
            </SidebarMenu>
          </SidebarGroup>
        </SidebarHeader>
        <SidebarContent>
          <SidebarGroup>
            <SidebarMenu>
              <SidebarMenuItem>
                <NavLink end className="navlink" to="/profile" state={{ backOrgId }}>
                  <MainNavItem iconName="user">Profile</MainNavItem>
                </NavLink>
              </SidebarMenuItem>
              {billingAccounts?.data.length && (
                <SidebarMenuItem>
                  <SidebarItem
                    label="Billing"
                    icon="chart-bar"
                    items={billingAccounts.data.map((billingAccount) => ({
                      label: billingAccount.name,
                      link: `/billing/${billingAccount.id}`,
                      key: billingAccount.id,
                    }))}
                    onClick={() => {}}
                  ></SidebarItem>
                  {/* <NavLink end className="navlink" to={`/billing/${billId}`} state={{ backOrgId }}>
                    
                  </NavLink> */}
                </SidebarMenuItem>
              )}
            </SidebarMenu>
          </SidebarGroup>
        </SidebarContent>
      </Sidebar>
      <SidebarInset>
        {isMobile && <SidebarTrigger />}
        <Card height="100" radius={{ base: '0', tablet: 'md' }}>
          <ScrollableMainLayout>{children}</ScrollableMainLayout>
        </Card>
      </SidebarInset>
    </SidebarProvider>
  );
};
