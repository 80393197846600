import { Box, Card, Heading } from '@hyphen/hyphen-components';
import { OrganizationBillingAccount } from '../../../services/billingAccount';

//TODO clean up once payloads are the same between the two routes
export const UsageDetails = ({ org }: { org: OrganizationBillingAccount }) => {
  return (
    <>
      <Heading as="h5" size="sm">
        {org.name}
      </Heading>
      <Card padding="xl">
        <Card.Section gap="xl" display="flex">
          <Box
            gap={{ base: 'lg', tablet: 'sm' }}
            direction="row"
            justifyContent={{ base: 'center', tablet: 'space-between' }}
            wrap
          >
            <UsageItem label="ENV Pulls" value={(org.env?.pull || 0).toLocaleString()} />
            <UsageItem label="ENV Push" value={(org.env?.push || 0).toLocaleString()} />
            <UsageItem label="Links Created" value={(org.link?.created || 0).toLocaleString()} />
            <UsageItem label="Net.Info Requests" value={(org.netInfo?.requests || 0).toLocaleString()} />
            <UsageItem label="Toggle Evaluations" value="0" />
          </Box>
        </Card.Section>
      </Card>
    </>
  );
};

const UsageItem = ({ label, value }: { label: string; value: string }) => (
  <Box direction="column" alignItems="flex-start">
    <Box fontSize="2xl" fontWeight="bold">
      {value}
    </Box>
    <Box fontSize="sm" color="secondary">
      {label}
    </Box>
  </Box>
);
