import { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import PageHeader from '../../components/PageHeader';
import { ScrollableMainLayout } from '../../components/ScrollableMainLayout';
import { Route, Routes } from 'react-router-dom';
import ClickToCopy from '../../components/common/ClickToCopy';
import Error from '../../Error';
import ProjectSettings from './Projects/ProjectSettings';
import ProjectOverview from './Projects/ProjectOverview';
import { TabNavLink, TabNav } from '../../components/TabNav';
import ProjectAccess from './Projects/ProjectAccess';
import ProjectToggles from './Toggles/ProjectToggles';
import ProjectConnections from '../../components/projects/ProjectConnections';
import { useProject } from '../../providers/ProjectProvider';
import { Skeleton } from '../../components/common/Skeleton';
import { Box, useIsMobile } from '@hyphen/hyphen-components';
import { BreadCrumbPortal } from '../../components/common/Breadcrumb';
import { useOrganizationAbilityContext } from '../../components/auth/OrganizationAbilityProvider';

export default function ProjectContainer() {
  const { project, isLoading, error } = useProject();
  const ability = useOrganizationAbilityContext();

  const canManageProject = useMemo(() => (project?.id ? ability.can('manage', project) : false), [ability, project]);

  const isMobile = useIsMobile();

  const pageHeaderProps = useMemo(() => {
    if (isLoading) {
      return {
        title: <Skeleton height={isMobile ? '25px' : '40px'} width="200px" />,
        description: <Skeleton height="30px" width="100px" />,
        favoriteId: '',
      };
    }
    return {
      title: project?.name,
      description: <ClickToCopy text={project?.alternateId || ''} fontSize="xs" />,
      favoriteId: project?.id,
    };
  }, [isLoading, project?.name, project?.alternateId, project?.id, isMobile]);

  if (!isLoading && error) return <Error />;

  return (
    <ScrollableMainLayout fullWidth>
      <Helmet>
        <title>{project?.name || ''}</title>
      </Helmet>
      {!isLoading && project && <BreadCrumbPortal entity={project} />}
      <PageHeader {...pageHeaderProps} />
      <TabNav>
        <TabNavLink to="">Overview</TabNavLink>
        <TabNavLink to="toggles">Toggles</TabNavLink>
        <TabNavLink to="connections">Connections</TabNavLink>
        <TabNavLink to="access">Access</TabNavLink>
        <TabNavLink to="settings">Settings</TabNavLink>
      </TabNav>

      <Routes>
        <Route path="" element={<ProjectOverview />} />
        <Route path="toggles" element={<ProjectToggles />} />
        <Route
          path="connections"
          element={
            isLoading ? null : canManageProject ? (
              <ProjectConnections />
            ) : (
              <Box fontSize="sm">You do not permission</Box>
            )
          }
        />
        <Route path="access" element={<ProjectAccess />} />
        <Route path="settings" element={<ProjectSettings />} />
      </Routes>
    </ScrollableMainLayout>
  );
}
